import * as React from "react";
import { Header, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const FAQPage = () => {
  return (
    <Container>
      <Segment vertical className="faqtooti">
        <Header as="h2">
          <Icon name="question" />
          <Header.Content>
            FAQ
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical className="faqtooti">
        <p>Q: What about my privacy?</p>
        <p>A: tooti collects only the data necessary to provide the service. Our business model does not include selling our users' data!</p>
        <br></br>
        <p>Q: I can't scan the barcode. Why?</p>
        <p>A: In some Android versions you need to allow tooti to access not only the camera but also the storage. This is required for the temporary storage of the scanned barcode. Please go to your phone's settings and enable tooti to use your phone's storage.</p>
        <br></br>
        <p>Q: Why I can't get data for some barcodes that I scan?</p>
        <p>A: Currently our database is quite big and covers many products. We work on expanding our database so it will include in the future more barcodes.</p>
        <br></br>
        <p>Q: What is the meaning of the grey strawberries?</p>
        <p>A: tooti's index can range between zero to five strawberries, where five means good for your health, and zero means a recommendation not to consume this food. The grey strawberries are "place holders" for the missing good strawberries. It is intended to help you see more clearly that a product is less healthy.</p>
        <br></br>
        <p>Q: How do you calculate nutrition highlights?</p>
        <p>A: The nutrition highlights are based on FDA's recommended daily consumption.</p>
        <br></br>
        <p>Q: Which ingredients do you highlight?</p>
        <p>A: The ingredients were selected based on FDA's recommended nutrition.</p>
        <br></br>
        <p>Q: tooti shows me an ingredient that is not indicated on the package. For example, palm Oil is shown in the results but the nutrition label on the product does not have any reference to Palm Oil. Why do I get a warning?</p>
        <p>A: Some food ingredients appear under more than one commercial description. For example, palm oil can be mentioned as vegetable oil.</p>
        <br></br>
        <p>Q: tooti used to work fine on my device but now I get only rating and purchase options. Why?</p>
        <p>A: tooti operates in a "freemium" model in which tooti provides free 250 detailed queries per month and 20 queries per day. Any additional detail query requires payment.</p>
        <br></br>
        <br></br>
      </Segment>
    </Container>
  );
};

export default withLayout(FAQPage);
